import * as React from "react";
import { Container } from "@mui/material";
import Content from "../components/content/Content";
import resume from "../assets/resume.json";
import StarBackground from "../components/threefiber/StartBackground";

import "../styles/components/card.css"

export default function Home() {
  document.title = resume.basics.name;
  return (
    <>
      <StarBackground></StarBackground>
      <Container>
        <div className={"card"}>
          <Content></Content>
        </div>
      </Container>
    </>
  );
}
