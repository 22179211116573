import React from "react";
import { Canvas, useThree, useFrame } from '@react-three/fiber'

import { Stars } from "@react-three/drei";
import { Vector3 } from 'three'

function Rig() {
  const { camera, mouse } = useThree()
  const vec = new Vector3()

  return useFrame(() => {
    camera.position.lerp(vec.set(mouse.x, mouse.y, camera.position.z), 0.05)
    camera.lookAt(0, 0, 0)
  })
}

function StarBackground() {

  return (
    <div style={{width: '100%', height: '100%', zIndex:0, position: "absolute", color: "black"}}>
      <Canvas style={{zIndex:-10}} camera={{ position: [0, 0, 6] }}>
        <Stars
          radius={200}
          depth={100}
          count={1000}
          factor={10}
          saturation={10}
          fade={true}>
        </Stars>
        <Rig />
      </Canvas>
    </div>
  )
}
 
export default StarBackground;