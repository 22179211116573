import ThemeToggler from "../themes/ThemeToggler";

import Header from "../components/layout/Header";
import Socials from "../components/layout/Socials";

// import { useLocation } from "react-router-dom";

export default function Layout({ children }) {

  // const location = useLocation();
  // const hideHeader = location.pathname.startsWith("/Projects/");
  
  return (
    <>
      <Header />
      <ThemeToggler />
      <Socials />
      {children}
    </>
  );
}
