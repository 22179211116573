import React from "react";
import me from "../../assets/me.jpeg";

export default function HomeImage() {

  const containerStyles = {
    width: "30vh",
    paddingBottom: "30vh", // Maintain a 1:1 aspect ratio (change this value for different ratios)
    position: "relative",
    overflow: "hidden",
  };

  const imgStyles = {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover", // Maintain aspect ratio without stretching
    transition: "transform 0.5s",
    backfaceVisibility: "hidden",
    borderRadius: "50%",
  };

  return (
    <div style={containerStyles}>
      <div style={{ ...imgStyles }}>
        <img src={me} alt="avatar front" style={imgStyles} />
      </div>
    </div>
  );
}
